import React from 'react';
import styles from './styles.module.scss';
import global_styles from '../global.module.scss';
import * as MENU from '../../../../constants/menu';
import * as DICTIONARY_MAIN_PAGE from '../../../../constants/dictionary/main-page';
import SITE from '../../../../config/site';
import Logotypes from '../../img/logotypes';
import { IAuthFooter } from '../../../../components/Auth/index.interface';
import image_payment_ssl from '../../../../img/payment_ssl.svg';
import image_payment_mastercard from '../../../../img/payment_mastercard.svg';
import image_payment_visa from '../../../../img/payment_visa.svg';
import { isHolidayActive } from '../../../../constants/holiday';

export default function AuthFooter({ openTermModal, openAffiliatsModal }: IAuthFooter) {
    const activeHoliday = isHolidayActive();

    const logoLightSrc =
        activeHoliday && Logotypes.images[`logo_${activeHoliday?.name}_light.svg`]
            ? Logotypes.images[`logo_${activeHoliday?.name}_light.svg`]
            : Logotypes.images[`logo_light.svg`];
    return (
        <div className={styles.main_footer}>
            <div className={global_styles.new_main_container}>
                <div className={styles.footer_top}>
                    <div className={styles.footer_top_row_1}>
                        <div className={styles.footer_col}>
                            <div className={styles.footer_logo}>
                                <img src={logoLightSrc} width="178" height="64" alt={SITE.brand} />
                            </div>
                            <div className={styles.main_footer_copyright}>
                                <small>Astrasoft projects Ltd</small>
                                <small>Ifigeneias 14, 3036, Limassol, Cyprus</small>
                                <small>Registration number HE 409810</small>
                                <small>
                                    E-mail:{' '}
                                    <a href="mailto:support@myspecialdates.com">
                                        support@myspecialdates.com
                                    </a>
                                </small>
                                <small className={styles.age_restriction}>
                                    You must be 18+ to use this site.
                                </small>
                            </div>
                        </div>
                        <div className={styles.footer_info_images}>
                            <img src={image_payment_ssl} width="91" height="41" alt="ssl payment" />
                            <img
                                src={image_payment_mastercard}
                                width="73"
                                height="51"
                                alt="mastercard payment"
                            />
                            <img
                                src={image_payment_visa}
                                width="80"
                                height="36"
                                alt="visa payment"
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.footer_bottom}>
                    <div className={styles.footer_bottom_row}>
                        <div className={styles.footer_bottom_links}>
                            <div onClick={() => openTermModal(MENU.LINK_COOKIE)}>Cookie policy</div>
                            <div onClick={() => openTermModal(MENU.LINK_PRIVACY)}>
                                Privacy Policy
                            </div>
                            <div onClick={() => openTermModal(MENU.LINK_TERMS)}>
                                TERMS AND CONDITIONS
                            </div>
                            <div onClick={() => openTermModal(MENU.LINK_RETURN_REFUND)}>
                                Refund Policy
                            </div>
                            <div onClick={openAffiliatsModal}>
                                {DICTIONARY_MAIN_PAGE.FOOTER_AFFILIATES}
                            </div>
                            <div onClick={() => openTermModal(MENU.LINK_STANDARDS)}>
                                Community Standards
                            </div>
                            <div onClick={() => openTermModal(MENU.LINK_DISPUTE_MANAGEMENT)}>
                                {DICTIONARY_MAIN_PAGE.FOOTER_DISPUTE_RESOLUTION}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
