import React from 'react';
import AuthImage from '../../img/auth';
import styles from './styles.module.scss';
import global_styles from '../global.module.scss';

export default function AuthQuestions() {
    return (
        <div className={styles.main_info_block}>
            <div className={global_styles.new_main_container}>
                <div className={styles.main_info_block_list}>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['nmp_info_1.svg']}
                            alt="Register for free"
                            className={styles.main_info_block_item_ico}
                        />
                        <div className={styles.main_info_block_item_title}>Register for free</div>
                        <div className={styles.main_info_block_item_text}>
                            Enjoy fair fees and free registration
                        </div>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['nmp_info_2.svg']}
                            alt="Free photo galleries"
                            className={styles.main_info_block_item_ico}
                        />
                        <div className={styles.main_info_block_item_title}>
                            Free photo galleries
                        </div>
                        <div className={styles.main_info_block_item_text}>
                            Get access to a vast amount of charge-free visual content
                        </div>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['nmp_info_4.svg']}
                            alt="Lots of effecient tools"
                            className={styles.main_info_block_item_ico}
                        />
                        <div className={styles.main_info_block_item_title}>
                            Lots of effecient tools
                        </div>
                        <div className={styles.main_info_block_item_text}>
                            Make your communication a breeze with such tools as likes, chats, and
                            letters
                        </div>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['nmp_info_5.svg']}
                            alt="Genuine interest in talking"
                            className={styles.main_info_block_item_ico}
                        />
                        <div className={styles.main_info_block_item_title}>
                            Genuine interest in talking
                        </div>
                        <div className={styles.main_info_block_item_text}>
                            Every member of our community longs for meaningful communication
                        </div>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['nmp_info_6.svg']}
                            alt="Manual profile verification"
                            className={styles.main_info_block_item_ico}
                        />
                        <div className={styles.main_info_block_item_title}>
                            Manual profile verification
                        </div>
                        <div className={styles.main_info_block_item_text}>
                            Our moderation team thoroughly monitors every participant
                        </div>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['nmp_info_7.svg']}
                            alt="Safety & Privacy"
                            className={styles.main_info_block_item_ico}
                        />
                        <div className={styles.main_info_block_item_title}>Safety & Privacy</div>
                        <div className={styles.main_info_block_item_text}>
                            Your conversations are absolutely safe with us
                        </div>
                    </div>
                    <div className={styles.main_info_block_item}>
                        <img
                            src={AuthImage.images['nmp_info_8.svg']}
                            alt="24/7 Help"
                            className={styles.main_info_block_item_ico}
                        />
                        <div className={styles.main_info_block_item_title}>24/7 Help</div>
                        <div className={styles.main_info_block_item_text}>
                            Any problems? Contact our Support Team 24/7
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
